:root {
  --headerColor: #132152;
  --appTitleColor: #67abbf;
  --iconColor: #222;
  --buttonColor: #222;
  --fontColor: #222;
  --companyLogo: url('../images/lexLogo.png');
  --logoWidth: 200px;
  --logoHeight: 38px;
  --logoML: 15px;
}
