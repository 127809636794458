.formBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
}

form .input {
  position: relative;
  opacity: 0;
  z-index: 99;
  height: 200px;
}

form .filelabel {
  text-align: center;
  margin-top: -109px;
}

.fileError {
  color: red;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  border-radius: 20px !important;
}
