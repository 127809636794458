.home {
    color: #fff;
}

.textFields {
    margin: 12px 0 !important;
}

.actionButton {
    display: flex;
    justify-content: flex-end;
}

.error {
    color: red !important;
    margin: 0 !important;
    margin-top: -8px !important;
}

.actionButton button {
    border-radius: 20px !important;
}

.leftMargin {
    margin-left: 8px;
}

.parent {
  display: flex;
  justify-content: space-between;
  width: 100%;  
  border-bottom: 1px solid #00000024;
  margin-top: 5px;

}

.parent:hover {
  cursor: pointer;
  opacity: 1;
  color: rgb(11, 11, 107);
}

.buttonoverlapmulti{
  position: absolute;
  min-width: 65px;
  right: 50px !important;
  margin-top: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
  padding: 0;
}

.buttonoverlapmulti:hover {
  opacity: 1;
}

.parent:hover .buttonoverlapmulti{
    opacity: 1;
  transition: opacity 1s;

}

.title {
  font-size: 30px;
  font-weight: 500;
  margin-left: 20px;
  text-transform: capitalize;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00000024 !important
}

.nameHeader {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #236064;
  border-bottom: 2px solid #5555559c;
  font-size: 18px;
  font-weight: 700;
  padding: 6px 10px;
  margin: 0 0 12px 0 !important;
}

.nameHeader :first-child {
  margin-left: 10px;
}

.tile{
  font-size: 16px !important;
  margin-left: 20px;
  white-space: nowrap;
  max-width: 900px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  
}

.subs {
  font-size: 12px;
  font-style: italic;
}

.rightAction {
  display: flex;
  align-items: center;
}

.editor {
  min-height: 280px;
}

.author {
  font-size: 16px;
  font-style: italic;
  display: flex;
  justify-content: flex-end;
}
