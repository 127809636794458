
.home {
    color: #fff;
  }
  
  .textFields {
    margin: 10px 0 !important;
  }
  
  .actionButton {
    display: flex;
    justify-content: flex-end;
  }
  
  .actionButton button {
    border-radius: 20px !important;
    margin: 5px;
  }
  
  .groupButtonTab {
    display: flex;
    align-items: flex-start;
  }
  
  .formBody {
    height: 200px !important;
    border: dashed !important;
    display: grid;
    margin-bottom: 20px;
  }
  
  form .input {
    position: relative;
    opacity: 0;
    z-index: 99;
    height: 200px;
  }
  
  form .filelabel {
    text-align: center;
    margin-top: -109px;
  }
  
  .fileError {
    color: red;
  }
  
  .actionAttactmentButton {
    display: flex;
    justify-content: flex-end;
  }
  
  .actionAttactmentButton button {
    border-radius: 20px !important;
    margin-top: 45px;
  }
  
  .detailListCustom {
    display: flex;
    font-size: 16px;
    text-transform: capitalize;
  }
  
  .detailListCustom .wordAnd {
    text-transform: lowercase !important;
  }
  
  .detailListCustom div:first-child {
    min-width: 255px;
  }
  
  .valueDetails {
    display: flex;
    justify-content: space-between;
  }
  
  .groupButtonShow {
    display: flex;
    align-items: flex-start;
  }

  .rank {
      max-width: 20% !important;
  }

.checkDetailsHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .checkDetailsHeader h3 {
    margin: 0;
  }

  .cell {
    /* height: 15px !important; */
    padding: 3px;
    /* align-items: center; */
    /* margin: 8px; */
    text-transform: capitalize;
  }

  .flex{
    display: flex;
    align-items: center;
  }

  .flex :last-child {
    margin-left: 5px;
  }
