.inclusive_dates {
  width: 160px !important;
}

.leave_type\.name {
  width: 130px !important;
}

.without_pay {
  width: 110px;
}

.reason {
  width: 250px;
}

.officer\.name {
  width: 265px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 8px !important;
  align-items: center !important;
  display: flex !important;
}

.fc-event-time{
  display : none;
}
.fc-time{
  display : none;
}

.fc .fc-button-primary {
  border: 2px solid !important;
}

.fc-daygrid-day-events {
  margin-right: 4px;
  margin-left: 4px;
}

.name_formal {
  width: 300px !important;
}

.position\.name {
  width: 350px !important;
}

.office_no {
  width: 150px !important;
}

.account_name {
  width: 300px;
  text-align: center;
}

.loan_date {
  width: 100px;
  text-transform: capitalize;
}

.maturity_date {
  width: 100px;
  text-transform: capitalize;
}

.principal_amount {
  width: 150px;
  text-transform: capitalize;
}

.loan_payment {
  width: 250px;
  text-transform: capitalize;
}

.loan_payment div {
  border-bottom: 1px solid;
  font-weight: bold;
  text-align: center;
}

.loan_payment th {
  width: 100px !important;
  background-color: #ff0404;
  text-align: left;
}

.loan_payment th:last-child {
  width: 60% !important;
  text-align: right;
}

.balance {
  text-transform: capitalize;
}
