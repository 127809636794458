.home {
    color: #fff;
}
  
.textFields {
    margin: 12px 0 !important;
}
  
.actionButton {
    display: flex;
    justify-content: flex-end;
}
  
.error {
    color: red !important;
    margin: 0 !important;
    margin-top: -8px !important;
}
  
.actionButton button {
    border-radius: 20px !important;
}

.leftMargin {
    margin-left: 8px;
}

.amountBody {
    width: 100%;
    margin: 0 10px 0 0 !important;
  }
  
  .amountBody input {
    text-align: right !important;
  }
