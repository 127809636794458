.headerContent {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  align-items: center;
  /* color: aliceblue; */
  font-weight: 600;
  padding: 0 10px;
  margin-top: 10px;
}
.tableBody {
  max-height: 300px;
  overflow: auto;
}
.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 10px !important;
  margin-top: -60px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}
.actionButton {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 10px;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.yearTextField {
    margin-left: 10px !important;
    width: 40%;
}
