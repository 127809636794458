.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  margin-top: 10px;
  border-radius: 20px !important;
}

.flex {
  display: flex;
}

.groupFields {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.groupFields .textFields {
  width: 272px !important;
}
.groupFields .branchField {
  width: 272px !important;
}

.groupFields .departmentField {
  width: 272px !important;
}

.groupFieldsRegister {
  display: flex;
  justify-content: space-between;
}

.groupFieldsRegister div {
  width: 270px;
}

.RegistryField {
  margin: 12px 0 !important;
}
