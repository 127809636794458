.home {
  color: #fff;
}


.actionButton {
  display: flex;
  justify-content: flex-end !important;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 8px 4px -15px;
}

.leftMargin {
  margin-left: 8px;
}
