@import "CssGlobalVariables/globalVariables.css";

.root {
  display: flex !important;
}

.companyLogo {
  background-image: var(--companyLogo);
  width: var(--logoWidth);
  height: var(--logoHeight);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: var(--logoML);
}

.appBar {
  background-color: var(--headerColor) !important;
  z-index: 1201 !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.appBarShift {
  background-color: var(--headerColor);
  width: calc(100% - 260px) !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  margin-left: 260px !important;
}

.menuButton {
  margin-right: 36px !important;
}

.hide {
  display: none !important;
}

.drawer {
  flex-shrink: 0 !important;
  white-space: nowrap !important;
}

.drawer.drawerOpen {
  width: 260px !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.toolbar {
  display: flex !important;
  padding: 0 8px !important;
  min-height: 63px !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.content {
  min-height: 100vh;
  padding-top: 80px !important;
  padding-inline: 12px;
  padding-bottom: 16px;
  flex-grow: 1 !important;
}

.MuiDrawer-paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.drawer.drawerClose {
  width: 65px !important;
  overflow-x: hidden !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.drawerOpen1 {
  width: 260px !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.drawerClose2 {
  width: 65px;
  overflow-x: hidden !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.headerCurrentUser {
  display: flex;
}

.headerCurrentUser h3 {
  margin-right: 13px;
}

.headerCurrentUser span {
  text-transform: capitalize !important;
}

.headerCurrentUser a {
  text-decoration: none;
  color: aliceblue;
}

.body {
  max-width: 1365px !important;
  margin: 0 auto !important;
}

.flexCustom {
  display: inline-flex !important;
}

.parentLabel {
  width: 140px;
}

.lastLabels span {
  width: 110px !important;
  white-space: initial !important;
}

.childParentLabel span:first-child {
  white-space: pre-wrap !important;
  display: contents !important;
}

.linkBody a {
  font-size: 16px;
  text-decoration: none;
  color: #000;
}

.childSub {
  width: 266px !important;
}

.childSub div:nth-child(2) {
  margin-right: 5px !important;
}

.childSubBody {
  border-radius: unset !important;
  box-shadow: inherit !important;
  margin: 0 !important;
  margin-left: -73px !important;
}

.childSubBody::before {
  background: none !important;
}

.listItem a {
  padding-left: 30px !important;
}

.linkItem a {
  display: block !important;
  padding: 10px;
}

.subBody a:first-child {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
}

.subBody {
  display: contents !important;
}

.none {
  display: contents !important;
}

.flexCustom:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.linkBody {
  background-color: #fff;
  position: absolute;
  margin-top: 9px;
  left: 120px;
  width: 240px;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}

.linkBody div .linkItem:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.linkList {
  display: flex;
  align-items: center;
}

.iconsLink {
  color: #fff;
  font-size: 32px !important;
}

.linkOpen {
  transform: none;
  transition: opacity 326ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 42px;
  left: 1056px;
  transform-origin: 0 26px;
}

.linkClose {
  display: none;
}

.closeBody {
  flex-grow: 1;
}

.openBody {
  flex-grow: 1;
  position: relative;
}

.lastLabel {
  display: contents !important;
}

.appLoading {
  background: #f07808 !important;
  margin-top: -8px;
}

.appLoading div:first-child {
  background: #9c4a07 !important;
}

.appLoading div:last-child {
  background: #f07808 !important;
}

.companyName {
  border: 1px solid #efefef;
}

.companyName p {
  font-size: 20px;
  text-align: center;
  padding: 20px;
  width: 250px;
  white-space: break-spaces;
  font-family: verdana, geneva, sans-serif;
}

.displayNone {
  display: none;
}

.userButtons {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

@media screen and (max-width: 600px) {
  .drawerClose2 {
    display: none !important;
  }

  .content {
    flex-grow: 1 !important;
  }
}

.li_menu {
  padding-block: 16px !important;
}