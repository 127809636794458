.theadCell {
  background-color: #fff !important;
  border: none !important;
  padding: 6px 2px !important;
  font-size: 13px !important;
  font-weight: 800 !important;
  text-align: left !important;
}

.boxHeader {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.boxSubHeader {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.tableTitle {
  padding-block: 10px !important;
  padding-inline: 0 !important;
  border-top: 1px solid #e9e9e9;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: blue;
}

.sectionHeader {
  padding-block: 10px !important;
  text-align: center !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  background-color: #efefef !important;
}

.tableRows {
  cursor: pointer !important;
}

.tableRows:hover{
  background-color: #efefef !important;
}

.importantTableCell {
  padding-block: 5px !important;
  border: none !important;
  font-size: 13px !important;
  font-weight: 800 !important;
  color: #000 !important;
}

.summaryCell{
  padding-block: 5px !important;
  border: none !important;
  font-size: 13px !important;
  color: #000 !important;
}

.cellLabel {
  padding-block: 5px !important;
  border: none !important;
  font-size: 13px !important;
  font-style: italic !important;
  color: #000 !important;
}

.cellComputationValue {
  padding-block: 5px !important;
  border: none !important;
  font-size: 13px !important;
  color: #000 !important;
}

.emphasizedTableCell {
  padding-block: 5px !important;
  border: none !important;
  font-style: italic;
  font-size: 13px !important;
  font-weight: 800 !important;
  color: #000 !important;
}

.selectStyles {
  font-size: 12px !important;
}

.errorOverlay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-block: 50px !important;
}

.errorOverlay kbd {
  font-size: 12px;
}
