.home {
    color: #fff;
}
  
.textFields {
    margin: 12px 0 !important;
}
  
.actionButton {
    display: flex;
    justify-content: flex-end;
}

.flex {
    display: flex;
    align-items: center !important;
    
}
  
.error {
    color: red !important;
    margin: 0 !important;
    margin-top: -8px !important;
}
  
.actionButton button {
    border-radius: 20px !important;
}

.leftMargin {
    margin-left: 8px;
}

.table {
    min-width: 700px;
  }
  
  .appbar {
    background-color: #095c75;
  }
  
  .title {
    flex-grow: 1;
    font-weight: initial;
    margin-left: 10px;
    text-transform: capitalize;
  }
  
  .head {
    display: flex;
  }
  
  .rightAction {
    display: flex;
    align-items: center;
  }
  
  .header {
    display: flex;
    align-items: center;
  }
  
  .taskTransaction {
    display: flex;
    align-items: center;
    height: 20px;
    padding: 10px;
    margin: 15px;
    text-transform: capitalize;
  }
  
  .itemMargin {
    margin-left: 2;
  }
  
  .cell {
    height: 20px;
    padding: 10px;
    align-items: center;
    margin: 15px;
    text-transform: capitalize;
  }
  
  .actions {
    width: 100px !important;
  }
  
  .tableTitle {
    display: flex;
    justify-content: space-between;
  }
  
  .tileBody {
    display: flex;
    align-items: center;
  }

.tabBody {
    margin-top: 0px !important;
}

.tabBody div {
  padding: 0px;
  min-height: 24px;
}


.flex div:first-child {
    min-width: 150px
}    

.sendButton{
    margin: 12px 0 !important;
}

.sendButton button {
    height: 39px !important;
    width: 100% !important;
}

.flexButton {
    display: flex;
    align-items: flex-end;
    justify-content: space-between !important;

}